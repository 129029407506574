// src/components/Footer.js

import React from 'react';
import '../styles/Footer.css';

const Footer = ({ menuDescription, footerColor }) => {
  return (
    <footer className="site-footer" style={{ backgroundColor: footerColor }}>
      <div className="footer-widget-area">
        <div className="container">
          <div className="footer-content">
            {/* Render the menu description if available */}
            {menuDescription && <p className="menu-description">{menuDescription}</p>}

            {/* Flex container to align the logo and text next to each other */}
            <div className="footer-logo-text">
              <div className="footer-logo">
                <img src="/assets/images/xrbax.png" alt="Logo" />
              </div>
              <div className="footer-text">
                <h2>
                  Design by{' '}
                  <a href="https://xrbax.com" target="_blank" rel="noopener noreferrer">
                    XRBax
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
