// src/components/MenuExpired.js

import React from 'react';
import { Button, Typography, Box, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import '../styles/MenuExpired.css';

const MenuExpired = () => {
  return (
    <Container maxWidth="sm" className="menu-expired-container">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          textAlign: 'center',
          bgcolor: '#F2E2E7',
          borderRadius: 3,
          p: 4,
        }}
      >
        <WarningIcon sx={{ fontSize: 60, color: '#B84265', mb: 2 }} />
        <Typography
          variant="h4"
          gutterBottom
          sx={{ color: '#782345', fontWeight: 'bold', fontSize: '28px' }}
        >
          منوی شما منقضی شده است
        </Typography>
        <Typography variant="body1" sx={{ color: '#5E1B35', mb: 3 }}>
          متاسفانه دسترسی به این منو ممکن نیست زیرا اشتراک آن به پایان رسیده است. لطفا اشتراک خود را شارژ کنید تا دوباره به منو دسترسی پیدا کنید.
        </Typography>
        <Button
  component="a"
  href="https://api.menubax.com/login"
  target="_blank" // Opens in a new tab
  rel="noopener noreferrer"
  variant="contained"
  sx={{
    backgroundColor: '#782345',
    color: '#ffffff',
    fontWeight: 'bold',
    '&:hover': { backgroundColor: '#5E1B35' },
  }}
>
  شارژ حساب
</Button>

      </Box>
    </Container>
  );
};

export default MenuExpired;


