import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import '../styles/Preloader.css';

const Preloader = ({ isVisible }) => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    fetch('/assets/lottie/loader.json')
      .then((response) => response.json())
      .then((data) => setAnimationData(data));
  }, []);

  if (!isVisible || !animationData) return null;  // Hide preloader if not visible or animation data not loaded

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="preloader">
      <div className="preloader-logo">
        <img src="/assets/images/xrbax.png" alt="Logo" />
      </div>
      <div className="preloader-animation">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
    </div>
  );
};

export default Preloader;
