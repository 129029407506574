import React from 'react';
import '../styles/Cart.css';  // Import necessary CSS for styling

const Cart = ({ cartItems, updateQuantity, removeFromCart }) => {
  // Calculate total price
  const totalPrice = cartItems.reduce(
    (total, item) => total + parseFloat(item.price) * item.quantity,
    0
  );

  return (
    <div className="cart-container">
      <h2>سفارشات</h2>
      {cartItems.length === 0 ? (
        <p>فعلا هیچی</p>
      ) : (
        <>
          <ul className="cart-items">
            {cartItems.map((item) => (
              <li key={item.itemId} className="cart-item">
                {/* Item Details */}
                <div className="item-details">
                  <span className="item-name">{item.itemName || 'Unnamed Item'}</span>
                  <span className="item-price"> {parseFloat(item.price).toFixed(2)}</span>
                    <span className="item-quantity">x {item.quantity}</span>
                </div>
                {/* Quantity Controls and Remove Button */}
                <div className="cart-item-controls">
                  <button
                    className="quantity-btn"
                    onClick={() => updateQuantity(item.itemId, item.quantity + 1)}
                  >
                    +
                  </button>
                  <button
                    className="quantity-btn"
                    onClick={() => updateQuantity(item.itemId, item.quantity - 1)}
                    disabled={item.quantity <= 1}
                  >
                    -
                  </button>
                  <button className="remove-btn" onClick={() => removeFromCart(item.itemId)}>
                    <img src="/assets/icons/remove.png" alt="Remove" className="remove-image" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
          {/* Display total price */}
          <div className="total-price">
            <h3>مجموع: {totalPrice.toFixed(2)}</h3>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
