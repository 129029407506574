// src/App.js

import React from 'react';
import { Routes, Route } from 'react-router-dom'; // Import Routes and Route
import MenuApp from './components/MenuApp'; // Import the MenuApp component
import MenuExpired from './components/menu-expired';
import './styles/App.css';

function App() {
  return (
    <Routes>
      <Route path="/:clientID/:menuID" element={<MenuApp />} />
      <Route path="/menu-expired" element={<MenuExpired />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function Home() {
  return <div>Please access the menu using the correct URL format.</div>;
}

function NotFound() {
  return (
    <div>
      <h2>Page Not Found</h2>
      <p>Please check the URL and try again.</p>
    </div>
  );
}

export default App;
