// src/components/PageTitle.js

import React from 'react';
import '../styles/PageTitle.css';

const PageTitle = ({ titleBackgroundColor, menuTitle, titleFont }) => {
  return (
    <div className="page-title-area" style={{ backgroundColor: titleBackgroundColor }}>
      <h2 className="page-title" style={{ fontFamily: titleFont }}>
        {menuTitle}
      </h2>
    </div>
  );
};

export default PageTitle;
