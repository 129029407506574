import React from 'react';

const ModernMenu = ({ menuData }) => {
  return (
    <div className="menu-container">
      <h1>Modern Menu Layout</h1>
      {/* Render the modern layout based on menuData */}
      <div>
        {menuData.categories.map((category) => (
          <div key={category.categoryId}>
            <h2>{category.categoryName}</h2>
            {/* Render category items */}
            <ul>
              {category.items.map((item) => (
                <li key={item.itemId}>{item.itemName}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModernMenu;
