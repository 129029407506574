// src/utils/api.js

export const fetchMenuData = async (menuDataUrl) => {
  try {
    let data;

    if (process.env.REACT_APP_ENV === 'development') {
      // Import the local JSON data for development
      const localData = await import('../data/menu.json');
      data = localData.default || localData; // Handle both default exports and named exports
      
    } else {
      if (!menuDataUrl) {
        throw new Error('Menu data URL is not provided.');
      }
      // Fetch the menu data from the provided URL in production
      const response = await fetch(menuDataUrl);
      
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      data = await response.json();
    }

    return data;
  } catch (error) {
    console.error('Error fetching menu data:', error);
    return null;
  }
};
