// src/components/MenuApp.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Preloader from './Preloader';
import PageTitle from './PageTitle';
import DefaultMenu from './DefaultMenu';
import ModernMenu from './ModernMenu';
import Footer from './Footer';
import Cart from './Cart';
import { fetchMenuData } from '../utils/api';
import '../styles/App.css';

function MenuApp() {
  const { clientID, menuID } = useParams();
  const navigate = useNavigate(); // Initialize navigate hook

  const [loading, setLoading] = useState(true);
  const [menuData, setMenuData] = useState(null);
  const [layoutTemplate, setLayoutTemplate] = useState('default');
  const [cart, setCart] = useState([]);
  const [modelViewerLoaded, setModelViewerLoaded] = useState(false);
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);
  const [coverImagesLoaded, setCoverImagesLoaded] = useState(false);

  // Helper functions
  const getBrightness = (hexColor) => {
    const hex = hexColor.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const getRgbaOrHex = (color) => {
    if (color.startsWith('rgba')) {
      return color;
    } else if (color.startsWith('#')) {
      return hexToRgba(color, 0.7);
    }
    return color;
  };

  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  // Hook to dynamically load the model-viewer script
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js';

    script.onload = () => {
      console.log('model-viewer script loaded');
      setModelViewerLoaded(true);
    };

    script.onerror = () => {
      console.error('Failed to load the model-viewer script.');
      setModelViewerLoaded(true); // Proceed even if script fails to load
    };

    document.head.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Hook to fetch menu data based on clientID and menuID
  useEffect(() => {
    const fetchData = async () => {
      try {
        const menuDataUrl = `https://api.menubax.com/public/menus/client-${clientID}/menu-${menuID}.json`; // Dynamically build the menu URL
        const data = await fetchMenuData(menuDataUrl);

        // Check the status of the menu
        if (data && data.status === 'archived') {
          // If the menu is archived, navigate to the expired menu page
          navigate('/menu-expired');
          return;
        }

        setMenuData(data);

        // Set the layoutTemplate based on the JSON data
        if (data && data.layoutTemplate) {
          setLayoutTemplate(data.layoutTemplate);
        }
      } catch (error) {
        console.error('Error fetching menu data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [clientID, menuID, navigate]);

  // Preload cover images
  useEffect(() => {
    if (menuData) {
      // Extract all cover images from menuData
      const coverImageUrls = [];
      menuData.categories.forEach((category) => {
        category.items.forEach((item) => {
          if (item.coverImage) {
            coverImageUrls.push(item.coverImage);
          }
        });
      });

      // Preload cover images
      let imagesToLoad = coverImageUrls.length;
      if (imagesToLoad === 0) {
        setCoverImagesLoaded(true);
      } else {
        coverImageUrls.forEach((url) => {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            imagesToLoad--;
            if (imagesToLoad === 0) {
              setCoverImagesLoaded(true);
            }
          };
          img.onerror = () => {
            imagesToLoad--;
            if (imagesToLoad === 0) {
              setCoverImagesLoaded(true);
            }
          };
        });
      }
    }
  }, [menuData]);

  // Extract font information from colorThemes
  const colorThemes = menuData?.colorThemes || [];
  const fontUrl = colorThemes[colorThemes.length - 1]; // Get the font URL

  // Function to extract the font family name from the font URL
  const getFontFamilyName = (fontUrl) => {
    if (!fontUrl) return null;
    try {
      const url = new URL(fontUrl);
      const familyParam = url.searchParams.get('family'); // e.g., 'Vazirmatn:wght@100..900&display=swap'
      const familyName = familyParam.split(':')[0]; // 'Vazirmatn'
      return familyName.replace(/\+/g, ' '); // Replace '+' with ' ' if necessary
    } catch (error) {
      console.error('Error parsing font URL:', error);
      return null;
    }
  };

  const fontFamilyName = getFontFamilyName(fontUrl);

  // Hook to dynamically add the font link to the document head
  useEffect(() => {
    if (fontUrl) {
      // Create a link element
      const link = document.createElement('link');
      link.href = fontUrl;
      link.rel = 'stylesheet';

      // Append the link element to the head
      document.head.appendChild(link);

      // Apply the font family to the body
      if (fontFamilyName) {
        document.body.style.fontFamily = `'${fontFamilyName}', sans-serif`;
      }

      // Cleanup function to remove the link when the component unmounts or fontUrl changes
      return () => {
        document.head.removeChild(link);
      };
    }
  }, [fontUrl, fontFamilyName]);

  // Get the background color from colorThemes (if available) or use a default
  const backgroundColor =
    menuData && menuData.colorThemes && menuData.colorThemes.length > 0
      ? menuData.colorThemes[0]
      : '#ffffff';

  const textColor = getBrightness(backgroundColor) > 128 ? '#000' : '#fff';

  const footerColor =
    menuData && menuData.colorThemes && menuData.colorThemes.length > 1
      ? menuData.colorThemes[1]
      : '#E1E1E1';

  const titleBackgroundColor =
    menuData && menuData.colorThemes && menuData.colorThemes.length > 2
      ? getRgbaOrHex(menuData.colorThemes[2])
      : 'rgba(30, 30, 30, 0.7)';

  // Extract titleFont from fontFamilyName
  const titleFont = fontFamilyName || 'sans-serif';

  // Hook to update the body's background and text colors
  useEffect(() => {
    if (backgroundColor) {
      document.body.style.backgroundColor = backgroundColor;
      document.body.style.color = textColor;
    }
  }, [backgroundColor, textColor]);

  const menuTitle = menuData ? menuData.menuName : 'Menu';
  const menuIconUrl =
    menuData && menuData.backgroundImages && menuData.backgroundImages.length > 0
      ? menuData.backgroundImages[0]
      : null;

  const topBackgroundImage =
    menuData && menuData.backgroundImages && menuData.backgroundImages.length > 1
      ? menuData.backgroundImages[1]
      : null;

  // New useEffect to wait for the background image to load
  useEffect(() => {
    if (topBackgroundImage) {
      const img = new Image();
      img.src = topBackgroundImage;
      img.onload = () => {
        setBackgroundImageLoaded(true);
        console.log('Background image loaded');
      };
      img.onerror = () => {
        console.error('Failed to load background image');
        setBackgroundImageLoaded(true); // Proceed even if image fails to load
      };
    } else {
      // If there's no background image, consider it as loaded
      setBackgroundImageLoaded(true);
    }
  }, [topBackgroundImage]);

  // Wait until the menu data, background image, model-viewer, and cover images are loaded
  if (loading || !backgroundImageLoaded || !modelViewerLoaded || !coverImagesLoaded) {
    return <Preloader isVisible={true} />;
  }

  return (
    <div className="App">
      {topBackgroundImage && (
        <div
          className="top-background"
          style={{ backgroundImage: `url(${topBackgroundImage})` }}
        >
          <PageTitle
            titleBackgroundColor={titleBackgroundColor}
            menuTitle={menuTitle}
            titleFont={titleFont}
          />
        </div>
      )}
      {menuIconUrl && (
        <img src={menuIconUrl} alt="Menu Icon" className="menu-icon-top-right" />
      )}
      {menuData && (
        <>
          {/* Render the menu based on layoutTemplate */}
          {layoutTemplate === 'modern' ? (
            <ModernMenu
              menuData={menuData}
              cartItems={cart}
              addToCart={addToCart}
              updateQuantity={updateQuantity}
              removeFromCart={removeFromCart}
            />
          ) : (
            <DefaultMenu
              menuData={menuData}
              cartItems={cart}
              addToCart={addToCart}
              updateQuantity={updateQuantity}
              removeFromCart={removeFromCart}
            />
          )}
          {/* Render Cart component */}
          <Cart
            cartItems={cart}
            updateQuantity={updateQuantity}
            removeFromCart={removeFromCart}
          />
        </>
      )}
      {/* Footer with menu description */}
      <Footer
        menuDescription={menuData?.description || 'Welcome to our cafe!'}
        footerColor={footerColor}
      />
    </div>
  );

  // Functions to manage cart items
  function addToCart(item, quantity) {
    setCart((prevCart) => {
      const existingItem = prevCart.find((cartItem) => cartItem.itemId === item.itemId);
      if (existingItem) {
        // Update the quantity of the existing item
        return prevCart.map((cartItem) =>
          cartItem.itemId === item.itemId ? { ...cartItem, quantity } : cartItem
        );
      } else {
        // Add new item to cart with specified quantity
        return [...prevCart, { ...item, quantity }];
      }
    });
  }

  function removeFromCart(itemId) {
    setCart((prevCart) => prevCart.filter((item) => item.itemId !== itemId));
  }

  function updateQuantity(itemId, newQuantity) {
    if (newQuantity <= 0) {
      removeFromCart(itemId);
    } else {
      setCart((prevCart) =>
        prevCart.map((item) =>
          item.itemId === itemId ? { ...item, quantity: newQuantity } : item
        )
      );
    }
  }
}

export default MenuApp;
