// src/components/ProgressiveImage.js

import React, { useState, useEffect } from 'react';

const ProgressiveImage = ({ lowResImage, highResImage, alt, className }) => {
  const [currentImage, setCurrentImage] = useState(lowResImage);
  const [isHighResLoaded, setIsHighResLoaded] = useState(false);
  const [is3DModel, setIs3DModel] = useState(false);

  useEffect(() => {
    // Determine if the highResImage is a 3D model
    const is3D = /\.(glb|gltf|obj|mtl)$/i.test(highResImage);
    setIs3DModel(is3D);

    if (is3D) {
      // For 3D models, no need to preload
      setIsHighResLoaded(true);
    } else {
      // Preload the high-resolution image
      const img = new Image();
      img.src = highResImage;
      img.onload = () => {
        setCurrentImage(highResImage);
        setIsHighResLoaded(true);
      };
    }
  }, [highResImage]);

  if (is3DModel) {
    // Define the attributes for model-viewer
    const modelViewerAttributes = {
      'auto-rotate': true,
      'camera-controls': true,
      ar: true,
    };

    // Return the <model-viewer> component with correct attributes
    return (
      <model-viewer
        src={highResImage}
        alt={alt}
        class={className}  // Use 'class' instead of 'className'
        style={{ width: '100%', height: '200px' }}
        {...modelViewerAttributes}
      ></model-viewer>
    );
  } else {
    // Return the image as before
    return (
      <img
        src={currentImage}
        alt={alt}
        className={className}
        style={{
          transition: 'opacity 0.5s ease-in-out',
          opacity: isHighResLoaded ? 1 : 0.7,
        }}
      />
    );
  }
};

export default ProgressiveImage;
