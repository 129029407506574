// src/components/DefaultMenu.js

import React, { useState, useRef, useEffect } from 'react';
import '../styles/DefaultMenu.css';
import ProgressiveImage from './ProgressiveImage';

const DefaultMenu = ({ menuData, addToCart, removeFromCart }) => {
  const [selectedCategory, setSelectedCategory] = useState(menuData.categories[0]);
  const [quantities, setQuantities] = useState({});
  const [cartVisible, setCartVisible] = useState({});
  const categorySliderRef = useRef(null);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const getBrightness = (hexColor) => {
    const hex = hexColor.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const itemBackgroundColor =
    selectedCategory.colorThemes && selectedCategory.colorThemes.length > 3
      ? selectedCategory.colorThemes[3]
      : '#ffffff';

  const textColor = getBrightness(itemBackgroundColor) > 128 ? '#000' : '#fff';

  // Extract category panel background color from menuData.colorThemes[3]
  const categoryPanelColor =
    menuData.colorThemes && menuData.colorThemes.length > 3
      ? menuData.colorThemes[3]
      : '#cccccc'; // Default color if not specified

  const handleQuantityChange = (item, amount) => {
    setQuantities((prevQuantities) => {
      const newQuantity = Math.max((prevQuantities[item.itemId] || 0) + amount, 0);

      if (newQuantity === 0) {
        setCartVisible((prevVisible) => ({ ...prevVisible, [item.itemId]: false }));
        removeFromCart(item.itemId);
      } else {
        addToCart(item, newQuantity);
      }

      return {
        ...prevQuantities,
        [item.itemId]: newQuantity,
      };
    });
  };

  const handleCartIconClick = (item) => {
    setCartVisible((prevVisible) => ({
      ...prevVisible,
      [item.itemId]: true,
    }));
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [item.itemId]: 1,
    }));
    addToCart(item, 1);
  };

  return (
    <div className="menu-container">
      {/* Categories */}
      <div className="category-panel" style={{ backgroundColor: categoryPanelColor }}>
        <div className="category-slider" ref={categorySliderRef}>
          {menuData.categories.map((category) => {
            const unselectedColor =
              category.colorThemes && category.colorThemes.length > 0
                ? category.colorThemes[0]
                : '#ccc';

            const selectedColor =
              category.colorThemes && category.colorThemes.length > 2
                ? category.colorThemes[2]
                : '#888';

            const isCategorySelected = selectedCategory.categoryId === category.categoryId;
            const categoryBackgroundColor = isCategorySelected ? selectedColor : unselectedColor;
            const categoryTextColor = getBrightness(categoryBackgroundColor) > 128 ? '#000' : '#fff';

            return (
              <div
                key={category.categoryId}
                className={`category-item ${isCategorySelected ? 'selected' : ''}`}
                style={{
                  backgroundColor: categoryBackgroundColor,
                  color: categoryTextColor,
                }}
                onClick={() => handleCategoryClick(category)}
              >
                <img
                  src={category.imageUrl}
                  alt={category.categoryName}
                  className="category-icon"
                />
                <p style={{ color: categoryTextColor }}>{category.categoryName}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Items List or Grid based on layoutTemplate */}
      <div
        className={`items-container ${
          selectedCategory.layoutTemplate === 'grid' ? 'grid-layout' : 'list-layout'
        }`}
        style={{
          '--category-background': itemBackgroundColor,
          '--text-color': textColor,
        }}
      >
        {selectedCategory.items.map((item) => (
          <div key={item.itemId} className="item">
            {/* Display the image, carousel, or 3D model */}
            <ItemDisplay item={item} />

            <div className="item-details" style={{ color: textColor }}>
              <h3>{item.itemName}</h3>
              <span className="item-price">{item.price}</span>
            </div>
            {item.description && (
              <p className="item-description" style={{ color: textColor }}>
                {item.description}
              </p>
            )}
            {item.customizableOptions && (
              <div className="item-options">
                {Object.keys(item.customizableOptions).map((option) => (
                  <span key={option} className="custom-option">
                    {option}
                  </span>
                ))}
              </div>
            )}
            {/* Add to cart with quantity control */}
            {cartVisible[item.itemId] ? (
              <div className="cart-controls">
                <button
                  className="quantity-btn"
                  onClick={() => handleQuantityChange(item, -1)}
                >
                  -
                </button>
                <span className="quantity">{quantities[item.itemId] || 1}</span>
                <button
                  className="quantity-btn"
                  onClick={() => handleQuantityChange(item, 1)}
                >
                  +
                </button>
              </div>
            ) : (
              <button className="cart-icon-btn" onClick={() => handleCartIconClick(item)}>
                <img
                  src="/assets/icons/add-to-cart.png"
                  alt="Add to Cart"
                  className="cart-icon"
                />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DefaultMenu;

// Helper Component to display item image, carousel, or 3D model
const ItemDisplay = ({ item }) => {
  const { itemName, coverImage, additionalImages } = item;

  // Function to check if a URL is a 3D model
  const is3DModel = (url) => {
    const modelExtensions = ['.glb', '.gltf', '.usdz', '.obj', '.mtl'];
    return modelExtensions.some((ext) => url.toLowerCase().endsWith(ext));
  };

  // Extract 3D model URLs and image URLs from additionalImages
  const modelUrls = additionalImages ? additionalImages.filter(is3DModel) : [];
  const imageUrls = additionalImages ? additionalImages.filter((url) => !is3DModel(url)) : [];
  

  // Include coverImage in the imageUrls if it's not already there
  if (coverImage && !imageUrls.includes(coverImage)) {
    imageUrls.unshift(coverImage);
  }

    // Remove the coverImage from imageUrls if present
    //const filteredImageUrls = imageUrls.filter((url) => url !== coverImage);

  // Implement carousel state
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (imageUrls.length > 1) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
      }, 3000); // Change image every 3 seconds

      return () => clearInterval(interval);
    }
  }, [imageUrls.length]);

  // Function to check if we have both .obj and .mtl files
  const hasObjAndMtl = () => {
    const hasObj = modelUrls.some((url) => url.toLowerCase().endsWith('.obj'));
    const hasMtl = modelUrls.some((url) => url.toLowerCase().endsWith('.mtl'));
    return hasObj && hasMtl;
  };

  // Get the .obj and .mtl files
  const getObjAndMtlUrls = () => {
    const objUrl = modelUrls.find((url) => url.toLowerCase().endsWith('.obj'));
    const mtlUrl = modelUrls.find((url) => url.toLowerCase().endsWith('.mtl'));
    return { objUrl, mtlUrl };
  };

  return (
    <div>
      {/* If there's a 3D model, use model-viewer */}
      {modelUrls.length > 0 ? (
        // For .glb and .gltf files
        modelUrls.some((url) => url.toLowerCase().endsWith('.glb') || url.toLowerCase().endsWith('.gltf')) ? (
          <model-viewer
            src={modelUrls.find((url) => url.toLowerCase().endsWith('.glb') || url.toLowerCase().endsWith('.gltf'))}
            poster={coverImage}
            alt={itemName}
            ar
            ar-modes="webxr scene-viewer quick-look"
            environment-image="neutral"
            auto-rotate
            camera-controls
            style={{ width: '100%', height: '300px' }}
            className="item-image model-viewer"
          ></model-viewer>
        ) : hasObjAndMtl() ? (
          // For .obj and .mtl files
          <model-viewer
            src={getObjAndMtlUrls().objUrl}
            alt={itemName}
            poster={coverImage}
            ar
            ar-modes="webxr scene-viewer quick-look"
            environment-image="neutral"
            auto-rotate
            camera-controls
            style={{ width: '100%', height: '300px' }}
            className="item-image model-viewer"
            ios-src={getObjAndMtlUrls().objUrl}
            mtl={getObjAndMtlUrls().mtlUrl}
          ></model-viewer>
        ) : (
          // If no valid 3D model, show the image
          coverImage && (
            <ProgressiveImage
              lowResImage={coverImage}
              highResImage={coverImage}
              alt={itemName}
              className="item-image"
            />
          )
        )
      ) : imageUrls.length > 1 ? (
        // If multiple images, use a carousel
        <div className="image-carousel">
          <img 
            src={imageUrls[currentImageIndex]}
            alt={`${itemName} ${currentImageIndex + 1}`}
            className="fade-in"
          />
        </div>
      ) : (
        // If only one image, display it
        coverImage && (
          <ProgressiveImage
            lowResImage={coverImage}
            highResImage={imageUrls[0] || coverImage}
            alt={itemName}
            className="item-image"
          />
        )
      )}
    </div>
  );
};
